.maptopheader {
    width: 100vw;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 5;
    background-color: #fff;
    border-radius: 0px 0px 16px 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
    padding: 20px;
    box-sizing: border-box;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.maptopheader::-webkit-scrollbar {
    display: none;
  }

.maptopheader::before {
    content: "";
    width: 20px;
    height: 80px;
    position: fixed;
    left: 0;
    top: 0;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    z-index: 5;
}

.maptopheader::after {
    content: "";
    width: 20px;
    height: 80px;
    position: fixed;
    right: 0;
    top: 0;
    background: rgb(255,255,255);
    background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    z-index: 5;
}

.maptopheadercontainer {
    display: flex;
    flex-direction: row;
    z-index: 2;
}

.stationcontainer {
    display: flex;
    margin-left: 24px;
    padding-right: 12px;
}

.stationcontaineritem {
    width: 56px;
    height: 56px;
    z-index: 2;
    border-radius: 56px;
    background-size: cover;
    margin: 0px 8px;
}

.poidot {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    border: 2px solid #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: transform 0.2s;
}
.poidot:active {
    transform: scale(1.1);
}

.poidot img {
    width: 24px;
    height: 24px;
}

.maptooltip {
    display: inline-block;
    max-width: 200px;
    background-color: #fff;
    padding: 6px 12px;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    font-size: 14px;
    text-align: center;
}

.maptooltip p {
    margin: 0px auto;
    font-size: 16px;
    font-weight: bold;
}


.maptooltip::after {
    content: "";
    background-image: url("img/tooltiparrow.svg");
    width: 20px;
    height: 10px;
    background-size: cover;
    position: absolute;
    bottom: -9px;
    left: calc(50% - 10px);
}

.stationpopupcontainer {
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
    width: 100vw;
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 48px 24px 24px 24px;
    box-sizing: border-box;
    visibility: visible;
    transition: opacity 0.2s, transform 0.2s, visibility 0.2s;
}

.stationpopupcontainerclosed {
    opacity: 0;
    transform: translateY(60px);
    visibility: hidden;
}

.stationpopup {
    width: 100%;
    background-color: #fff;
    padding: 56px 20px 20px 20px;
    box-sizing: border-box;
    border-radius: 20px;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.stationpopup p {
    margin: 0px auto;
    font-size: 18px;
    font-weight: bold;
}

.stationpopupimage {
    width: 56px;
    height: 56px;
    position: absolute;
    border-radius: 56px;
    background-size: cover;
    position: absolute;
    top: -30px;
    border: 3px solid #fff;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.16);
}

.openstationbtn {
    width: 100%;
    background-color: var(--main-color);
    padding: 16px;
    box-sizing: border-box;
    border-radius: 12px;
    color: #fff;
    margin: 0px auto;
    margin-top: 24px;
    font-weight: bold;
}

.mapbuttonbadge {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    transition: opacity 0.2s;
}

.mapbuttonbadge img {
    width: 24px;
    height: 24px;
    display: block;
}

.homebutton {
    left: 0;
    bottom: 56px;
    padding: 8px 16px 8px 12px;
    border-radius: 0px 40px 40px 0px;
}

.timebadge {
    right: 0;
    bottom: 56px;
    padding: 8px 12px 8px 12px;
    border-radius: 40px 0px 0px 40px;
}

.timebadge img {
    margin-right: 4px;
}

.stationmarkeractive {
    animation: jump 1s ease-in-out infinite;
}

@keyframes jump {
    0%, 100% {
      transform: translateY(0) scaleY(0.95);
    }
    50% {
      transform: translateY(-20px) scaleY(1.05);
    }
  }