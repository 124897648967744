@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap');


:root {
  --main-color: #669B12;
  --dark-color: #45332f;
  --font-body: 'Work Sans', sans-serif;
  --font-display: 'Work Sans', sans-serif;
  --font-display-weight: bold;
}

body {
  font-family: var(--font-body);
  line-height: 140%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

.wrapper {
  width: 85%;
  max-width: 500px;
  margin: 0px auto;
}

h1,h2,h3 {
  font-family: var(--font-display);
  font-weight: var(--font-display-weight);
}

h1 {
  font-size: 48px;
  line-height: 100%;
  margin-top: 12px;
  font-weight: normal;
}

h2 {
  font-size: 24px;
  line-height: 110%;
}

.button-primary {
  color: #fff;
  background-color: var(--main-color);
  text-align: center;
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

/* Shine animation 
@keyframes shine {
  0% {
    opacity: 0;
    transform: translate(-150%, -150%) rotate(45deg);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(150%, 150%) rotate(45deg);
  }
}

.button-primary::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  transform: rotate(45deg);
  animation: shine 2s linear infinite;
  pointer-events: none; 
}
*/

.button-secondary {
  color: #4F4F4F;
  border: 2px solid #bbb;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 18px;
  cursor: pointer;
}

.overviewwrapper {
  background-color: #fff;
  position: relative;
}

.trailcard {
  width: 100%;
  height: 400px;
  border-radius: 24px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #eee;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  margin-bottom: 24px;
 position: relative;
 overflow: hidden;
 cursor: pointer;
 transition: transform 0.2s;
}

.trailcard:active {
  /*transform: scale(0.95);*/
}

.trailcardlogo {
  width: 100%;
  z-index: 1;
}

.trailcard p {
  font-size: 13px;
  text-align: center;
  margin-bottom: 12px;
  margin-top: 12px;
  z-index: 1;
}

.trailcard h3 {
  font-size: 32px;
  font-weight: normal;
  text-align: center;
  margin: 0px;
  line-height: 120%;
  z-index: 1;
}

.carddoneicon {
  background-color: #92D469;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  border: 3px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 16px;
  right: 16px;
}

.carddoneicon img {
  width: 24px;
}

.badgecounter {
  display: flex;
  align-items: center;
}

.badgesmall {
  width: 48px;
  height: 48px;
  border-radius: 60px;
  margin-right: -16px;
}


.badgesmall-full {
  border: 2px solid #fff;
  box-shadow: 0px 4px 8px #0002;
}

.badgesmall-empty {
  /*border: 2px dashed #cecece;*/
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='%23fff' rx='100' ry='100' stroke='%23cecece' stroke-width='5' stroke-dasharray='2%2c8' stroke-dashoffset='64' stroke-linecap='round'/%3e%3c/svg%3e");
border-radius: 100px;
}

.badgecounterlabel {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.badgecounterlabel img {
  width: 24px;
  height: 24px;
}

.trailcardgradient {
  width: 100%;
  height: 300px;
  position: absolute;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
  bottom: 0;
  z-index: 0;
}

.mapbutton {
  color: #fff;
  background-color: #000;
  padding: 12px 16px;
  position: fixed;
  z-index: 4;
  bottom: 20px;
  font-weight: bold;
  border-radius: 16px;
  font-size: 18px;
  margin: 0px auto;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.2s;
}

.badgetopsection {
  width: 100%;
  background: rgb(35,67,80);
  background: linear-gradient(0deg, #275163 0%, rgb(20, 32, 42) 100%);
  position: relative;
  z-index: 0;
  transition: height 0.5s;
  color: #fff;
}

.badgetopsectionactive {
  /*max-height: 85vh;*/
  max-height: auto;
}


.badgetopsectionactive .badgecollectionsection {
  opacity: 1;
}

.badgetopsectionwhite {
  width: 100%;
  height: 40px;
  background-color: #fff;
  position: absolute;
  z-index: 3;
  bottom: -2px;
  border-radius: 24px 24px 0px 0px;
  box-shadow: 0px -12px 8px #0002;
}

.badgetopsectionwhite img {
  margin: 0px auto;
  display: block;
  margin-top: 16px;
  width: 48px;
}

.badgetopsectionwhitearrow {
  animation: arrowupdown 0.5s alternate infinite;
}

@keyframes arrowupdown {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(5px);
  }
}

.badge {
  width: 72px;
  height: 72px;
  background-size: cover;
  border-radius: 100%;
  border: 3px solid #fff;
  margin: 0px 6px;
  overflow: hidden;
  position: relative;
}

.badgeshine {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  
}

.badgedone {
  box-shadow: 0px 8px 16px #0005, inset 4px 4px 4px #0003;
  /*box-shadow: 0px 0px 24px #fffc, inset 4px 4px 4px #0003;
  animation: badgeglow 3s infinite alternate ease-in-out;*/
  overflow: hidden;
}

.badgedone:after {
	content:'';
  top:0;
	transform:translateX(100%);
	width:72px;
	height:72px;
	position: absolute;
	z-index:1;
	animation: slide 3s infinite;
  background: #fff0;
	background: linear-gradient(to right, #fff0 0%, #fffc 50%, #fff0 100%); /* W3C */
}


@keyframes badgeglow {
  from {
    box-shadow: 0px 0px 16px #fff5, inset 4px 4px 4px #0003;
  }
  to {
    box-shadow: 0px 0px 32px 4px #fffa, inset 4px 4px 4px #0003;
  }
}

.badgeinactive {
  filter: grayscale(1);
  opacity: 0.5;
  /*border: 4px dashed;*/
}

.badgetopsectioncontainer {
  display: flex;
  flex-direction: row;
  margin: 0px auto;
  justify-content: center;
  position: absolute;
  z-index: 2;
  width: 100%;
  bottom: 20px;
  transition: opacity 0.5s, transform 0.5s;
}

.badgecollectionheadline {
  font-size: 32px;
  width: calc(100% - 80px);
}

.badgecollectionwrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 32px;
  column-gap: 20px;
  margin: 48px 0px;
}

.badgecollectionitem {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
  min-height: 120px;
}

.badgecollectionitemempty {
  opacity: 0.3;
  width: 106px;
  /*animation: badgeemptyrotate 3s ease-in-out infinite alternate;*/
}

@keyframes badgeemptyrotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(20deg);}
}

.badgecollectionitem:active {
  transform: scale(0.95);
}

.badgecollectionitemtitle {
  margin-top: 16px;
  margin-bottom: 2px;
}

.badgecollectioniteminfotext {
  font-size: 12px;
  opacity: 0.7;
  margin: 0px auto;
}

.badgecollectionwrapper .badge {
  width: 100px;
  height: 100px;
}

.badgecollectionwrapper .badge::after {
  width: 100px;
  height: 100px;
}

.badgetopsectionactive .badgetopsectioncontainer {
  opacity: 0;
  transform: translateY(100px);
}

.badgetopsectionclosed .badgecollectionsection {
  transform: translateY(-100px);
  opacity: 0;
}

.badgecollectionsection {
  z-index: 1;
  transform: translateY(0px);
  overflow: scroll;
  height: 100%;
  width: 100%;
  padding: 40px 24px;
  box-sizing: border-box;
  transition: opacity 0.5s, transform 0.5s;
}

.badgetopsectionclosed {
  /*max-height: 140px;*/
}

.badgetopsectionclosed .badgetopsectionwhitearrow img {
  transform: rotate(180deg);
}

.badgeanimation {
  animation: badgeanimation 4s infinite linear;
}

@keyframes badgeanimation {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(-100px);
  }
}

.modalmap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ddddb2;
  z-index: 3;
  opacity: 1;
  transform: scale(1);
  transition: all 0.2s;
  visibility: visible;
  transition-timing-function: cubic-bezier(0.65,0.05,0.36,1);
}

.modalmapclosed {
  opacity: 0;
  transform: scale(1) translateX(-100%);
  visibility: hidden;
}

.regionswrapperdev {
  display: flex;
  justify-content: center;
}

.regionswrapperdev div {
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
}

.regionswrapperdevitem {
  background-color: #eee;
  font-weight: bold;
}

.loadingscreen {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  justify-content: center;
  align-items: center;
}

.loadingscreen .loadingicon {
  width: 40px;
  animation: loadingicon 1s linear infinite;
}

.loadingscreen .emptystateicon {
  max-width: 320px;
  width: 90%;
}

.emptystatescreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes loadingicon {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

.overviewlistbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0px;
}

.overviewlistbar p {
  font-size: 18px;
}

.mapbutton-secondary {
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 16px;
  border: 2px solid #C8C8C8;
  cursor: pointer;
}

.badgecountercont {
  font-size: 18px;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}

.badgecountercont b {
  font-size: 28px;
}


.badge-detail-home {
  width: 44px;
  height: 44px;
  background-size: cover;
  border-radius: 100%;
  border: 2px solid #fff;
  margin: 0px auto;
  box-shadow: 0px 4px 8px #0004, inset 6px 6px 6px #0003;
  position: relative;
  overflow: hidden;
  margin-left: 8px;
}

.badge-detail-home:after {
	content:'';
  top:0;
	transform:translateX(100%);
	width:88px;
	height:88px;
	position: absolute;
	z-index:1;
	animation: slide 3s infinite;
	 
  background: #fff0;
	background: linear-gradient(to right, #fff0 0%, #fffc 50%, #fff0 100%); /* W3C */
}


.modalbadge {
  width: 100vw;
  height: calc(100vh + 1px);
  position: fixed;
  top: -1px;
  left: 0;
  color: #fff;
  background-color: var(--dark-color);
  z-index: 3;
  opacity: 1;
  transform: scale(1);
  transition: all 0.2s;
  visibility: visible;
  /*overflow: scroll;*/
  transition-timing-function: cubic-bezier(0.65,0.05,0.36,1);
}

.modalbadgeclosed {
  opacity: 0;
  transform: scale(1) translateX(100%);
  visibility: hidden;
}

