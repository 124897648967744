.page-station {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  overflow: hidden;
  position: relative;
}


.content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: transform 0.3s;
}

.content-wrapper-before {
  transform: translateX( 100%);

}

.content-wrapper-after {
  transform: translateX(-100%);

}

.contentarrowcontainer {
  position: fixed;
  width: 100%;
  bottom: 0px;
  display: flex;
 justify-content: space-between;
 align-items: center;
 padding: 16px;
 box-sizing: border-box;
 background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);
}

.contentarrow {
  background-color: #fff;
  border-radius: 100%;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 6px;
  box-shadow: 0px 4px 12px #0001;
  transition: transform 0.2s;
}

.contentarrow:active {
  transform: scale(0.8);
}



.contentarrowback img {
  transform: rotate(180deg);
}

.contentarrow img {
  width: 32px;
  height: 32px;
}

.progressdotcont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.progressdot {
  width: 8px;
  height: 8px;
  opacity: 0.5;
  background-color: #fff;
  border-radius: 20px;
  margin: 2px;
  box-shadow: 0px 4px 12px #0001;
  transition: all 0.2;
}

.progressdotactive {
  width: 12px;
  height: 12px;
  opacity: 1;
}

/* VIDEO */

.page-content-video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #eee;
}

.page-content-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.videoplayicon {
  position: absolute;
  background-color: black;
  z-index: 2;
  width: 80px;
  height: 80px;
}

.videoplayicon {
  background-color: #000a;
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
  z-index: 5;
  transition: opacity 0.2s, transform 0.2s;
}

.videoplayicon img {
  width: 40px;
}

.videoplayiconhidden {
  opacity: 0;
  transform: scale(0.5);
}

/* QUIZ */

.page-content-quiz {
  background-color: #ededed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.page-content-quiz-wrapper {
  width: 85%;
  max-width: 600px;
  margin-top: auto;
  margin-bottom: auto;
  padding: 40px 0px 100px 0px;
}

.page-content-quiz-wrapper h1 {
  font-size: 28px;
  line-height: 120%;
  margin-bottom: 32px;
}

.quiz-box {
  display: grid;
  row-gap: 12px;
  column-gap: 12px;
}

.quiz-box-two {
  grid-template-columns: 1fr 1fr;
}

.quiz-item {
  width: 100%;
  border-radius: 12px;
  background-color: #fff;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 4px 12px #0001;
}

.quizitemimage {
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
}


.quizitemtext {
  padding: 16px;
  line-height: 140%;
}


.quiz-item.correct {
  /*background-color: #3b873b; /* Bright green background for correct answers */
  /*border-color: #3b873b; /* Darker green border color */
  color: #135113; /* Dark green text color */
  animation: pulse 1s;
  
}

.quiz-item.correct::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #0a890a66;
  position: absolute;
  top: 0;
  left: 0;
}

.quiz-item::after {
  content: "";
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 15px;
  right: 14px;
}

.quiz-item.correct::after {
  background-image: url("/src/img/quiz-correct.svg");
  animation: checkiconanimation 0.5s;
}

.quiz-item.incorrect {
  /*background-color: #fbe4e4; /* Bright red background for incorrect answers */
  /*border-color: #f3c0be; /* Darker red border color */
  color: #732221; /* Dark red text color */
  animation: shake 0.5s; /* Apply the shake animation */
}

.quiz-item.incorrect::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #85050266;
  position: absolute;
  top: 0;
  left: 0;
}

.quiz-item.incorrect::after {
  background-image: url("/src/img/quiz-incorrect.svg");
  animation: checkiconanimation 0.5s;
}

@keyframes checkiconanimation {
  0% {
    transform: scale(3); /* Normal size */
    opacity: 0;
  }
  100% {
    transform: scale(1); /* Slightly larger size */
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-5px); /* Move to the left */
  }
  40% {
    transform: translateX(5px); /* Move to the right */
  }
  60% {
    transform: translateX(-5px); /* Move to the left */
  }
  80% {
    transform: translateX(5px); /* Move to the right */
  }
  100% {
    transform: translateX(0); /* Back to center */
  }
}


@keyframes pulse {
  0%,
  100% {
    transform: scale(1); /* Normal size */
  }
  50% {
    transform: scale(1.05); /* Slightly larger size */
  }
}

/* IMAGE */

.page-content-image img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}